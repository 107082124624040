import { EditorScriptApi } from '../../api/api';
import { BasePageMigrator } from './base-page-migrator';
import {
  installEcomPlatform,
  addComponents,
  EcomComponent,
} from '@wix/ecom-platform-sdk';
import { ECOM_APP_DEF_ID } from '@wix/bookings-adapter-ooi-wix-sdk';
import { EcomStyleParams } from '@wix/ecom-platform-sdk/dist/es/src/types';
import { mapThankYouPageParams } from '../ecom-migration/style-transpiler';
import { PageId } from '../../constants';

export class EcomPagesMigrator extends BasePageMigrator {
  constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
    private editorScriptApi: EditorScriptApi,
    private debug,
  ) {
    super(editorSDK, appToken, instance);
  }

  public shouldMigrate() {
    return true;
  }

  public async execute() {
    await installEcomPlatform(this.editorSDK);
    await addComponents(this.editorSDK, [
      EcomComponent.CHECKOUT,
      EcomComponent.THANK_YOU_PAGE,
    ]);

    const checkoutParams = await this.getCheckoutParams();
    const newParams = await mapThankYouPageParams(checkoutParams);

    await this.setThankYouPageParams(newParams);
    if (!this.debug) {
      await this.markAsEcom();
    }
  }

  private async getCheckoutParams() {
    const siteStructure = await this.getSiteStructure();
    const checkoutStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
    );
    const compRef = await this.editorSDK.document.components.getById(
      this.appToken,
      {
        id: checkoutStructure.id,
      },
    );
    return this.editorSDK.document.tpa.getStyleParams(this.appToken, {
      compRef,
    });
  }

  private async setThankYouPageParams(params: EcomStyleParams) {
    const eComPublicApi =
      await this.editorSDK.document.application.getPublicAPI(this.appToken, {
        appDefinitionId: ECOM_APP_DEF_ID,
      });
    await eComPublicApi.setThankYouPageStyleParams(params);
  }

  private async markAsEcom() {
    const isReady = await this.editorScriptApi.setEcomCompatible();
    if (isReady) {
      await this.removeCheckoutPage();
    } else {
      throw new Error('failed to mark site as eCom compatible');
    }
  }

  private async removeCheckoutPage() {
    const allPages = await this.getAllSitePages();
    const bookingCheckoutPageData = allPages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
    );

    if (bookingCheckoutPageData) {
      await this.editorSDK.document.pages.remove(this.appToken, {
        pageRef: {
          id: bookingCheckoutPageData.id,
        },
        shouldShowEditorRemovePanel: false,
      });
    }
  }

  protected get widgetId() {
    return '';
  }
}
